let commands = [
  {
    name: "unknown",
    output(input) {
      return [
        {
          color: "white",
          content: `the command "${input[0]}" you entered is undefined!\n`,
          delay: 100,
        },
        {
          color: "white",
          content: input.find((arr) => arr === "--help")
            ? "try something like:\n" +
              "\n" +
              "    - Mastodon   > social\n" +
              "    - Gitea      > git\n" +
              "    - HeadgeDoc  > md\n" +
              "    - Jitsi      > meet\n"
            : 'show examples with "welcome --help"',
          delay: 200,
        },
      ];
    },
  },
  {
    name: "welcome",
    output(input) {
      let fontSize = window.getComputedStyle(document.body, null).fontSize
        .split("px", 1);
      return [
        { color: "white", content: "\nWelcome to:\n", delay: 100 },
        {
          color: "green",
          content: window.innerWidth / fontSize < 35
            ? "            __  \n" +
              "           /\\ \\  \n" +
              "  ____ __  \\_\\ \\____ _____  ____ \n" +
              " / ___\\\\ \\/\\ \\\\  __ \\\\  __\\/ __ \\ \n" +
              "/\\ \\__/ \\ \\_\\ \\\\ \\_\\ \\\\ \\_/\\  __/  \n" +
              "\\ \\____\\ \\____ \\\\____/ \\_\\\\ \\____\\\n" +
              " \\/____/\\/___/\\ \\___/ \\/_/ \\/____/\n" +
              "    __     /\\___/ \n" +
              "   /\\ \\__  \\/__/          @adb.sh\n" +
              "   \\ \\  _\\  ____ __  __  __ ______\n" +
              "    \\ \\ \\/ / __ \\\\ \\/\\ \\/\\ \\\\  __ \\\n" +
              "   __\\ \\ \\_\\ \\_\\ \\\\ \\/  \\/ / \\ \\/\\ \\\n" +
              "  /\\_\\\\ \\__\\\\____/ \\__/\\__/ \\ \\_\\ \\_\\\n" +
              "  \\/_/ \\/__//___/ \\/_/\\/_/   \\/_/\\/_/\n\n"
            : "            __                      __\n" +
              "           /\\ \\                    /\\ \\__\n" +
              "  ____ __  \\_\\ \\____ _____  ____   \\ \\  _\\  ____ __  __  __ ______\n" +
              " / ___\\\\ \\/\\ \\\\  __ \\\\  __\\/ __ \\   \\ \\ \\/ / __ \\\\ \\/\\ \\/\\ \\\\  __ \\\n" +
              "/\\ \\__/ \\ \\_\\ \\\\ \\_\\ \\\\ \\_/\\  __/  __\\ \\ \\_\\ \\_\\ \\\\ \\/  \\/ / \\ \\/\\ \\\n" +
              "\\ \\____\\ \\____ \\\\____/ \\_\\\\ \\____\\/\\_\\\\ \\__\\\\____/ \\__/\\__/ \\ \\_\\ \\_\\\n" +
              " \\/____/\\/___/\\ \\___/ \\/_/ \\/____/\\/_/ \\/__//___/ \\/_/\\/_/   \\/_/\\/_/\n" +
              "           /\\___/\n" +
              "           \\/__/                                             @adb.sh\n\n",
          delay: 300,
        },
        input.find((arr) => arr === "--help")
          ? {
            color: "white",
            content: "free services on this server:\n" +
              "\n" +
              "    - Mastodon   > social\n" +
              "    - Gitea      > git\n" +
              "    - HeadgeDoc  > md\n" +
              "    - Jitsi      > meet\n",
            delay: 500,
          }
          : {
            color: "white",
            content: 'show examples with "welcome --help"',
            delay: 600,
          },
      ];
    },
  },
  {
    name: "time",
    output() {
      return [
        { color: "white", content: "your current time is: ", delay: 100 },
        {
          color: "blue",
          content: function () {
            return new Date().toLocaleTimeString();
          }(),
          delay: 200,
        },
      ];
    },
  },
  {
    name: "date",
    output() {
      return [
        { color: "white", content: "your current date is: ", delay: 100 },
        {
          color: "blue",
          content: function () {
            return new Date().toLocaleDateString();
          }(),
          delay: 200,
        },
      ];
    },
  },
  {
    name: ["social", "mastodon", "Mastodon", "mstdn"],
    output: [
      { color: "blue", content: "\nMastodon", delay: 100 },
      { color: "white", content: " is loading\n", delay: 200 },
      { color: "white", content: "forwarding", delay: 400 },
      {
        color: "white",
        content: " ...",
        forward: "https://social.cybre.town",
        delay: 600,
      },
    ],
  },
  {
    name: ["git", "gitea", "Gitea"],
    output: [
      { color: "blue", content: "\nGitea", delay: 100 },
      { color: "white", content: " is loading\n", delay: 200 },
      { color: "white", content: "forwarding", delay: 400 },
      {
        color: "white",
        content: " ...",
        forward: "https://git.cybre.town",
        delay: 600,
      },
    ],
  },
  {
    name: ["md", "hedgedoc", "HedgeDoc"],
    output(input) {
      if (input.find((arr) => arr === "--help")) {
        return [
          {
            color: "white",
            content: "the syntax for this command is:\n",
            delay: 100,
          },
          { color: "white", content: "", delay: 200 },
          { color: "red", content: " [padname]", delay: 300 },
        ];
      }
      return input[1]
        ? [
          { color: "blue", content: "\nhedgedoc", delay: 100 },
          {
            color: "white",
            content: ` pad "${input[1]}" is loading\n`,
            delay: 200,
          },
          { color: "white", content: "forwarding", delay: 400 },
          {
            color: "white",
            content: " ...",
            forward: `https://md.cybre.town/${input[1]}`,
            delay: 600,
          },
        ]
        : [
          { color: "blue", content: "\nHedgeDoc", delay: 100 },
          { color: "white", content: " startpage is loading\n", delay: 200 },
          {
            color: "white",
            content: 'run with "--help" to show syntax\n',
            delay: 300,
          },
          { color: "white", content: "forwarding", delay: 400 },
          {
            color: "white",
            content: " ...",
            forward: "https://md.cybre.town",
            delay: 600,
          },
        ];
    },
  },
  {
    name: ["surl", "short-url", "shortURL"],
    output: [
      { color: "blue", content: "\nsurl", delay: 100 },
      { color: "white", content: " is loading\n", delay: 200 },
      { color: "white", content: "forwarding", delay: 400 },
      {
        color: "white",
        content: " ...",
        forward: "https://s.adb.sh",
        delay: 600,
      },
    ],
  },
  {
    name: ["meet", "jitsi", "Jitsi"],
    output(input) {
      if (input.find((arr) => arr === "--help")) {
        return [
          {
            color: "white",
            content: "the syntax for this command is:\n",
            delay: 100,
          },
          { color: "white", content: "meet", delay: 200 },
          { color: "red", content: " [roomname]", delay: 300 },
        ];
      }
      return input[1]
        ? [
          { color: "blue", content: "\nJitsi", delay: 100 },
          {
            color: "white",
            content: ` room "${input[1]}" is loading\n`,
            delay: 200,
          },
          { color: "white", content: "forwarding", delay: 400 },
          {
            color: "white",
            content: " ...",
            forward: `https://meet.adb.sh/${input[1]}`,
            delay: 600,
          },
        ]
        : [
          { color: "blue", content: "\nJitsi", delay: 100 },
          { color: "white", content: " startpage is loading\n", delay: 200 },
          {
            color: "white",
            content: 'run with "--help" to show syntax\n',
            delay: 300,
          },
          { color: "white", content: "forwarding", delay: 400 },
          {
            color: "white",
            content: " ...",
            forward: "https://meet.adb.sh",
            delay: 600,
          },
        ];
    },
  },
  {
    name: "cat",
    output: [
      {
        color: "red",
        content: "" +
          "    .       .\n" +
          "    \\`-\"'\"-'/\n" +
          "     } 6 6 {    \n" +
          "    =.  Y  ,=   \n" +
          "      /^^^\\  .\n" +
          "     /     \\  )\n" +
          "jgs (  )-(  )/ \n" +
          '     ""   ""',
        delay: 100,
      },
    ],
  },
];

export default {
  data() {
    return {
      commands,
    };
  },
  methods: {
    renderCommand(input) {
      let toRender = this.getCommand(input).output;
      return typeof toRender === "function"
        ? toRender(input.split(" "))
        : toRender;
    },
    getCommand(input) {
      input = input.split(" ", 1)[0];
      let command = commands.find(
        (command) =>
          Array.isArray(command.name)
            ? command.name.find(
              (altName) => altName === input,
            )
            : command.name === input,
      );
      return command ? command : commands[0];
    },
  },
};
