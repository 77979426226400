<template>
  <div @click="focusInput()" class="console" ref="console">
    <div class="content">
      <div class="group" v-for="(group, index) in history" :key="group">
        <pre><span class="host">{{group.host}}</span><span
          v-if="index !== history.length-1">{{group.command}}</span><input
          v-else v-model="group.command" ref="consoleInput" type="text" autocomplete="off"
          @keydown.enter.exact="displayCommand();"
          @keydown.up="group.command = getPreviousCommand(index);"
          @keydown.down="group.command = getNextCommand(index);"></pre>
        <pre v-if="group.output.length"><span v-for="output in group.output" :key="output" :class="output.color">{{output.content}}</span></pre>
      </div>
    </div>
  </div>
</template>

<script>
import commands from "@/renderCommands.js"

export default {
  name: "ConsoleWrapper",
  data(){
    return{
      history:[{
        host: "[cybre.town]$ ",
        command: "welcome --help",
        output: []
      }],
      currentHistoryPoint: undefined
    }
  },
  methods:{
    displayCommand(){
      let command = commands.methods.renderCommand(this.history[this.history.length-1].command);
      command.forEach((group, i) => {
        setTimeout(() => {
          this.history[this.history.length-1].output.push(group);
          if (group.forward) window.location = group.forward;
          if (command.length-1 === i){
            this.history.push({
              host: "[cybre.town]$ ",
              command: "",
              output: []
            });
            this.$forceUpdate();
            this.focusInput();
          }
          this.scrollToBottom();
        }, group.delay)
      })
      this.currentHistoryPoint = undefined;
    },
    getPreviousCommand(index){
      if (this.currentHistoryPoint === undefined) this.currentHistoryPoint = index;
      if (this.currentHistoryPoint > 0) this.currentHistoryPoint--;
      return this.history[this.currentHistoryPoint].command;
    },
    getNextCommand(index){
      if (this.currentHistoryPoint === undefined) this.currentHistoryPoint = index;
      if (this.currentHistoryPoint < this.history.length-1) this.currentHistoryPoint++;
      return this.history[this.currentHistoryPoint].command;
    },
    focusInput(){
      console.log(this.$refs.consoleInput)
      this.$nextTick(() => this.$refs.consoleInput[0].focus());
      this.scrollToBottom();
    },
    scrollToBottom(){
      this.$nextTick(() => this.$refs.console.scrollTop = this.$refs.console.scrollHeight);
    }
  },
  mounted() {
    this.displayCommand();
  }
}
</script>

<style scoped lang="scss">
  pre{
    margin: 0;
    padding: 0;
  }
  .console{
    color: #fff;
    overflow-y: auto;
    overflow-x: auto;
    .content{
      height: fit-content;
      width: fit-content;
      padding: 1rem;
      .group{
        .host{color: #02a594;}
        .white{color: #fff;}
        .green{color: #44dd88;}
        .blue{color: #0081b1;}
        .red{color: #ac311c;}
        input{
          background-color: #0000;
          border: 0;
          margin: 0;
          padding: 0;
          color: #fff;
          appearance: none;
          outline: none;
          font: inherit;
        }
      }
    }
  }
</style>
